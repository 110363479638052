import type { PartialDeep } from 'type-fest'

import { objectToCamelCase, objectToSnakeCase } from '@/composables/useCaseConvert'
import { $http } from '@/plugins/http'

export async function createBusiness(registerData: RegisterBusinessPayload): Promise<Partial<BusinessData>> {
  const response = await $http.$post('/v2/businesses', objectToSnakeCase(registerData))

  return objectToCamelCase(response)
}

export async function updateBillingAddress(
  businessId: string,
  addressData: Partial<Address> & { postal_code?: string }
): Promise<Partial<BusinessData>> {
  const response = await $http.$put<BusinessData>(`/v2/businesses/${businessId}/billing`, addressData)

  return objectToCamelCase(response)
}

export async function getBusiness(businessId: string): Promise<BusinessData> {
  const response = await $http.$get(`/v1/businesses/${businessId}`)

  return objectToCamelCase<BusinessData>(response)
}

export async function deleteBusiness(businessId: string): Promise<void> {
  await $http.$delete(`/v2/businesses/${businessId}`)
}

export async function getBusinessExtras(businessId: string): Promise<BusinessExtras[]> {
  const response = await $http.$get(`/v2/businesses/${businessId}/customextras`)

  return objectToCamelCase<BusinessExtras[]>(response)
}

export async function getBusinessSettings(businessId: string): Promise<BusinessPreferences> {
  const response = await $http.$get(`/v1/businesses/${businessId}/settings`)

  // Careful, the backend returns a nested object here
  const { settings } = objectToCamelCase<{ settings: BusinessPreferences }>(response)

  return settings
}

export async function updateBusinessSettings(
  businessId: string,
  settings: PartialDeep<BusinessPreferences>
): Promise<BusinessPreferences> {
  const response = await $http.$put(`/v1/businesses/${businessId}/settings`, objectToSnakeCase(settings))

  // Careful, the backend returns a nested object here
  const { settings: updatedSettings } = objectToCamelCase<{ settings: BusinessPreferences }>(response)

  return updatedSettings
}

export async function getAdmins(businessId: string): Promise<Admin[]> {
  const { admins } = await $http.$get<{ admins: Admin[] }>(`/v1/businesses/${businessId}/admins`)

  return admins
}

export async function makeAdmin(businessId: string, data: { user_ids: string[] }): Promise<void> {
  return await $http.$post(`/v1/businesses/${businessId}/change-to-admins`, data)
}

export async function revokeAdmin(businessId: string, data: { user_ids: string[] }): Promise<void> {
  return await $http.$post(`/v1/businesses/${businessId}/change-to-members`, data)
}

export async function getSignatureUsage(businessId: string, month: number, year: number): Promise<BlobResponse> {
  const response = await $http.get(`/v1/timeline/business/${businessId}/csv?month=${month}&year=${year}`)

  const data = await response.blob()

  return {
    data,
    name: `skribble-${businessId.slice(businessId.length - 4)}-${year}-${month}.csv`,
  }
}

export async function getMembers(businessId: string): Promise<MemberItem[]> {
  const response = await $http.$get<{ total_num_members: number; members: MemberItem[] }>(
    `/v1/businesses/${businessId}/members`
  )

  return objectToCamelCase(response.members)
}

export async function removeMember(businessId: string, memberId: string): Promise<void> {
  await removeMembers(businessId, { user_ids: [memberId] })
}

export async function removeMembers(businessId: string, data: { user_ids: string[] }): Promise<void> {
  return await $http.$post(`/v1/businesses/${businessId}/remove-members`, data)
}

export async function revokeCancellation(businessId: string): Promise<void> {
  return await $http.$post(`/v2/businesses/${businessId}/revoke_delete`)
}

export async function getUsageData(businessId: string): Promise<UsageData> {
  const usageData = await $http.$get(`/v2/businesses/${businessId}/subscriptions/usage`)

  return objectToCamelCase(usageData)
}

export async function createChargebeeSessionPortal(businessId: string): Promise<Function> {
  return await $http.$post(`/v2/businesses/${businessId}/chargebee/portal_session`)
}

/**
 * Updates the number of seats used by the business.
 *
 * We use a timestamp to let Stripe know about the cutoff date when adding partial seats.
 *
 * @param businessId The ID of the business to update
 * @param newTotal The new total number of seats
 * @param timestamp The timestamp of the cutoff date for partial seats
 */
export async function updateUsedSeats(
  businessId: string,
  newTotal: number,
  timestamp?: string
): Promise<ActivePricePlan['seats']> {
  const seatsData = await $http.$put(`/v2/businesses/${businessId}/subscriptions/seats`, { seats: newTotal, timestamp })

  return objectToCamelCase(seatsData)
}

export async function getSeals(businessId: string): Promise<SealInfo[]> {
  const response = await $http.$get<{ seals: { name: string; label: string; n_api: number; n_members: number }[] }>(
    `/v1/businesses/${businessId}/seals`
  )

  return response.seals.map<SealInfo>(seal => {
    return {
      accountName: seal.name,
      displayName: seal.label,
      amountApiKeys: seal.n_api,
      amountMembers: seal.n_members,
    }
  })
}
