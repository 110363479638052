import "core-js/modules/es.symbol.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import Vue from 'vue';
import VueI18n from 'vue-i18n';
var dateTimeFormats = {
  'en-gb': {
    datetime: {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    }
  },
  'de-ch': {
    datetime: {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    }
  },
  'fr-ch': {
    datetime: {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    }
  },
  it: {
    datetime: {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    }
  }
};
var numberFormats = {
  // NB: Technically, this is not correct, since the UK does not use EUR,
  // but `en-gb` is our fallback and EUR our default
  'en-gb': {
    currency: {
      style: 'currency',
      currency: 'EUR',
      currencyDisplay: 'code'
    }
  },
  'de-ch': {
    currency: {
      style: 'currency',
      currency: 'CHF',
      currencyDisplay: 'code'
    }
  },
  'fr-ch': {
    currency: {
      style: 'currency',
      currency: 'CHF',
      currencyDisplay: 'code'
    }
  }
};
Vue.use(VueI18n);
var i18n = function i18n(_ref, inject) {
  var app = _ref.app,
    store = _ref.store,
    $config = _ref.$config;
  // Set i18n instance on app
  // This way we can use it in middleware and pages asyncData/fetch
  var i18n = new VueI18n(_objectSpread(_objectSpread({
    dateTimeFormats: dateTimeFormats,
    numberFormats: numberFormats,
    locale: store.state.locale
  }, $config.public.mode !== 'development' ? {
    fallbackLocale: 'en-gb'
  } : {}), {}, {
    messages: {}
  }));
  app.i18n = i18n;
  inject('i18n', i18n);
  var formatCurrency = function formatCurrency(rawPrice) {
    var currency = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'EUR';
    var defaultCurrencyOptions = store.getters['billing/defaultCurrencyOptions'];
    return i18n.n(rawPrice, rawPrice % 1 === 0 ? _objectSpread(_objectSpread({}, defaultCurrencyOptions), {}, {
      maximumFractionDigits: 0,
      currency: currency
    }) : _objectSpread(_objectSpread({}, defaultCurrencyOptions), {}, {
      currency: currency
    }));
  };
  inject('formatCurrency', formatCurrency);
};
export default i18n;