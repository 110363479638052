import "core-js/modules/es.array.concat.js";
import { useI18n } from 'nuxt-i18n-composable';
import Avatar from '@/components/Avatar.vue';
import SkrButton from '@/components/button/SkrButton.vue';
import Logo from '@/components/Logo.vue';
import SkrDivider from '@/components/SkrDivider.vue';
import useUserPlanInfo from '@/composables/useUserPlanInfo';
import { useBusinessStore } from '@/stores/business';
import { useUserStore } from '@/stores/user';
import { useSystemStore } from '~/stores/system';
export default defineComponent({
  name: 'MainNav',
  components: {
    Avatar: Avatar,
    Logo: Logo,
    SkrButton: SkrButton,
    SkrDivider: SkrDivider
  },
  props: {
    active: {
      type: Boolean,
      default: false
    }
  },
  setup: function setup() {
    var businessStore = useBusinessStore();
    var _storeToRefs = storeToRefs(businessStore),
      isPrePricing2023 = _storeToRefs.isPrePricing2023;
    var _storeToRefs2 = storeToRefs(useUserStore()),
      firstName = _storeToRefs2.firstName,
      lastName = _storeToRefs2.lastName,
      isBusinessAdmin = _storeToRefs2.isBusinessAdmin,
      isBusinessMember = _storeToRefs2.isBusinessMember,
      highestSignatureQuality = _storeToRefs2.highestSignatureQuality;
    var _useUserPlanInfo = useUserPlanInfo(),
      activePricePlanId = _useUserPlanInfo.activePricePlanId,
      isNewFreePlan = _useUserPlanInfo.isNewFreePlan;
    var systemStore = useSystemStore();
    var displayedName = computed(function () {
      return "".concat(firstName.value.split(' ')[0], " ").concat(lastName.value);
    });
    var supportContact = computed(function () {
      return businessStore.settings.supportContact;
    });
    var brandingLogoUrl = computed(function () {
      var _businessStore$settin, _businessStore$settin2;
      return (_businessStore$settin = (_businessStore$settin2 = businessStore.settings.companyBranding) === null || _businessStore$settin2 === void 0 ? void 0 : _businessStore$settin2.logo.url) !== null && _businessStore$settin !== void 0 ? _businessStore$settin : '';
    });
    var platform = computed(function () {
      return systemStore.platform || 'CH';
    });
    var _useI18n = useI18n(),
      t = _useI18n.t;
    var getEmailContactLink = computed(function () {
      var _supportContact$value;
      return (_supportContact$value = supportContact.value) !== null && _supportContact$value !== void 0 && _supportContact$value.email ? "mailto:".concat(supportContact.value.email) : platform.value === 'EU' ? t('global.navigation.contact.link_form_de') : t('global.navigation.contact.link');
    });
    var getSupportContactLink = computed(function () {
      var _supportContact$value2;
      return (_supportContact$value2 = supportContact.value) !== null && _supportContact$value2 !== void 0 && _supportContact$value2.phone ? "tel:".concat(supportContact.value.phone) : platform.value === 'EU' ? t('global.navigation.call.call_support_form_de') : t('global.navigation.call.call_support_form');
    });
    return {
      userIsAdmin: isBusinessAdmin,
      userIsMember: isBusinessMember,
      displayedName: displayedName,
      isNewFreePlan: isNewFreePlan,
      isPrePricing2023: isPrePricing2023,
      activePricePlanId: activePricePlanId,
      supportContact: supportContact,
      brandingLogoUrl: brandingLogoUrl,
      highestSignatureQuality: highestSignatureQuality,
      platform: platform,
      getSupportContactLink: getSupportContactLink,
      getEmailContactLink: getEmailContactLink
    };
  },
  data: function data() {
    return {
      drawer: false
    };
  },
  watch: {
    active: function active() {
      this.drawer = this.active;
    },
    drawer: function drawer() {
      this.$emit('changed', this.drawer);
    }
  }
});